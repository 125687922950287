
<template>
  <div class="select-good1">
    <Table
      draggable
      border
      :columns="columns"
      :data="componentData.data"
      size="small"
      :key="refreshkey"
      @on-drag-drop="draggeTable"
    ></Table>
    <div
      class="add"
      @click="addGood"
      v-if="componentData.data && componentData.data.length < 50"
    >
      +添加商品({{ componentData.data.length }}/50)
    </div>
    <!--商品选择器-->
    <!-- <goods-selector
            v-model="show"
            multiple
            :limit="50"
            :current-list="componentData.data"
            @on-cancel="handleCancel"
            @on-change="handleChange"
        ></goods-selector> -->
    <!--商品单选选择器-->
    <!-- <goods-selector
            v-model="singleShow"
            :current="getSingData"
            @on-cancel="singleCancel"
            @on-change="singleChange"
        ></goods-selector> -->
    <select-good
      v-model="show"
      :currentCheckId="currentGoodId"
      @on-cancel="handleCancel"
      @on-change="handleChange"
    ></select-good>
  </div>
</template>

<script>
import mixin from "../mixin.js";
// import GoodsSelector from '@/components/decorate/decorate-components/selector/GoodsSelector'
import SelectGood from "@/components/decorate/decorate-components/new-components/select-goods";
export default {
  components: { SelectGood },
  mixins: [mixin],
  props: {
    currentInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      componentData: {},
      refreshkey: "0",
      show: false,
      singleShow: false,
      columns: [
        {
          key: "coverImageUrl",
          width: 94,
          title: "商品图片",
          align: "left",
          render: (h, params) => {
            return (
              <div class="goods-thumb">
                <img
                  src={params.row.coverImageUrl}
                  style={{
                    width: "60px",
                    height: "60px",
                    display: "block",
                    margin: "0 10px",
                  }}
                  alt=""
                />
              </div>
            );
          },
        },
        {
          key: "goodsTitle",
          title: "商品名称",
          width: 180,
          render: (h, params) => {
            // return <span>{params.row.goodsTitle}</span>;
            return (
              <ElInput
                size="small"
                style="width: 160px"
                value={params.row.goodsTitle}
                onInput={(val) => this.changeValue(params, val, 'goodsTitle')}
                placeholder={params.row.goodsTitle}
              ></ElInput>
            );
          },
        },
        {
          key: "priceText",
          title: "商品价格",
          width: 140,
          render: (h, params) => {
            // return <span>{params.row.goodsTitle}</span>;
            return (
              <ElInput
                size="small"
                style="width: 130px"
                value={params.row.priceText}
                onInput={(val) => this.changeValue(params, val, 'priceText')}
                placeholder="请输入商品价格"
              ></ElInput>
            );
          },
        },
        // {
        //   key: "price",
        //   title: "价格",
        //   align: "center",
        //   render: (h, params) => {
        //     return (
        //       <p class="price" style="text-align:center;">
        //         ￥{params.row.price}
        //       </p>
        //     );
        //   },
        // },
        {
          key: "option",
          title: "操作",
          align: "center",
          render: (h, params) => {
            return (
              <p class="option" style="text-align:center;">
                <span
                  onClick={() => {
                    this.replace(params);
                  }}
                >
                  替换
                </span>
                <span
                  onClick={() => {
                    this.delete(params);
                  }}
                >
                  删除
                </span>
              </p>
            );
          },
        },
      ],
      replaceIndex: -1, // 替换索引值
      currentGoodId: null,
    };
  },
  computed: {
    getSingData() {
      return this.componentData.data[this.replaceIndex] || {};
    },
  },
  methods: {
    draggeTable(nIndex, oIndex) {
      let item = { ...this.componentData.data[nIndex] };
      Object.assign(
        this.componentData.data[nIndex],
        this.componentData.data[oIndex]
      );
      Object.assign(this.componentData.data[oIndex], item);
    },
    handleCancel() {
      this.show = false;
    },
    singleCancel() {
      this.singleShow = false;
    },
    handleChange(val) {
      if (this.currentGoodId) {
        const data = this.componentData.data
        const index = data.findIndex(item => item.id === this.currentGoodId)
        const firstGood = val?.[0]
        if (index > -1) {
          this.componentData.data.splice(index, 1, {...firstGood});
          this.currentGoodId = null
        }
        return
      }
      if (this.componentData.data.length < 50) {
        this.componentData.data.push(...val);
      } else {
        this.$message.warning('最多添加50个商品')
      }
      this.refreshkey = Math.random();
      this.errorInfo?.id == "goods" && this.$emit("validateGoodsForm");
    },
    singleChange(val) {
      if (!val.id) {
        this.componentData.data.splice(this.replaceIndex, 1);
      } else {
        Object.assign(this.componentData.data[this.replaceIndex], {
          id: val.id,
          thumb: val.thumb,
          price: val.price,
          productprice: val.original_price,
          sales: val.sales * 1 + val.real_sales * 1,
          title: val.title,
          sub_name: val.sub_name,
          gid: val.id,
          bargain: 0,
          credit: 0,
          ctype: 0,
          has_option: val.has_option,
          type: val.type || val.goodstype,
        });
      }

      this.refreshkey = Math.random();
    },
    replace(params) {
      this.currentGoodId = params.row.id;
      this.show = true;
    },
    delete(params) {
      this.componentData.data.splice(params.index, 1);
    },
    addGood() {
      this.show = true;
    },

    changeValue (params, newVal, field) {
      const oldVal = this.componentData.data[params.index]
      this.$set(this.componentData.data, params.index, {
        ...(oldVal || {}),
        [field]: newVal
      })
    }
  },
  created() {
    this.componentData = this.currentInfo || this.currentModal;
  },
  mounted() {
    if (this.componentData?.defaultData?.length) {
      this.componentData.data = [];
      this.handleChange(this.componentData?.defaultData);
    }
  },
  beforeDestroy() {
    let data = this.componentData.data;
    this.componentData.defaultData = data?.length
      ? data
      : this.componentData.defaultData;
  },
};
</script>

<style lang="scss" scoped>
@import "../common.scss";
.select-good1 {
  /deep/ .ivu-table-cell {
    padding: 10px 0 !important;
  }
  /deep/ .ivu-table-row {
    td {
      border-right: none;
    }
  }
  /deep/ thead {
    th {
      background: #f4f6f8 !important;
      border-right: none;

      &:first-child {
        .ivu-table-cell {
          padding-left: 10px !important;
        }
      }
    }
  }

  /deep/ .goods-thumb {
    display: flex;
    align-items: center;
    .move-icon {
      margin-left: 10px;
      font-size: 24px;
      color: #b8b9bd;
    }
  }

  /deep/ .title {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */
    display: flex;
    // align-items: center;
    /* text/first */
    color: #262b30;
    text-align: left;
    .mark {
      @include font-12-16;
      color: #ed4014;
      background-color: #ffefe6;
      border-radius: 2px;
      margin-right: 4px;
      width: 18px;
      height: 18px;
      display: inline-block;
      text-align: center;
      line-height: 18px;
      &:nth-last-child(2) {
        margin-right: 6px;
      }
      &.real {
        background-color: #f0faff;
        color: $brand-color;
      }
      &.virtual {
        background-color: #e6fff9;
        color: #00c5c5;
      }
      &.secret {
        background-color: #ffcdd0;
        color: #ff000f;
      }
    }
    .icon {
      display: inline-block;
      width: 18px;
      height: 18px;
      line-height: 18px;
      background: #ffefe6;
      border-radius: 2px;
      color: #ed4014;
      font-family: PingFang SC;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      text-align: center;
      margin: 0 5px;
      flex-shrink: 0;
    }
  }
  /deep/ .price {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */
    text-align: center;
    /* text/first */
    color: #262b30;
  }
  /deep/ .option {
    display: flex;
    justify-content: center;
    span {
      font-family: PingFang SC;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height, or 133% */
      display: flex;
      align-items: center;
      cursor: pointer;
      /* brand/def */
      color: $brand-color;
      margin: auto 5px;
    }
  }
  .add {
    border: 1px solid #e9edef;
    border-top: none;
    box-sizing: border-box;
    line-height: 52px;
    text-align: center;
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: $brand-color;
    cursor: pointer;
  }
}
</style>
