<template>
  <select-link
    ref="select-link"
    :selectSourceType="'hotGoods'"
    :currentCheckId="currentCheckId"
    drawerTitle="选择商品"
    @link-selected="changeLink"
    @close="selectClose"
  ></select-link>
</template>

<script>
import SelectLink from "@/components/decorate/decorate-components/new-components/select-link";
export default {
  name: "SelectGoods",

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    currentCheckId: {
      type: Number,
      default: null,
    },
  },

  watch: {
    value: {
      async handler (newVal) {
        if (newVal) {
          await this.$nextTick()
          this.openSelectLink()
        }
      },
      immediate: true
    }
  },

  components: {
    SelectLink,
  },

  methods: {
    changeLink (val) {
      this.$emit("on-change", [val]);
    },

    selectClose () {
      this.$emit('input', false)
    },

    async openSelectLink() {
      this.$nextTick()
      this.$refs['select-link'].show = true;
      this.$refs['select-link'].init();
      this.$refs['select-link'].getConfigList();
    },
  }
};
</script>

<style>
</style>